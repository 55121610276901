<template>
  <!-- 组件大图详情 -->
  <div class="widget_large_mask" v-show="showWidget" @click="hideWidget">
    <WidgetPopup
      :imageData="currentImageData"
      :similarData="similarWidget"
      v-show="showWidget"
      @similarWidget="showImage"
      @hide="hideWidget"/>
  </div>

  <!-- 顶部导航栏组件 -->
  <TopNav />

  <!-- 第一屏背景图 -->
<div class="bannerbox">
  <img class="banner" src="../assets/images/banner.jpg">
  <div class="banner_content">
    <!-- 第一屏文案模块 -->
    <div class="slogan">图层可编辑，无需下载，复制粘贴使用<br>比AI更懂你的需求</div>
    <div class="sloganh5">AI智能创作<br>5秒生成UI界面</div>
    <!-- 第一屏搜索 -->
    <div class="searchbox">
        <img class="search_icon" src="../assets/images/icon_sousuo.svg">
        <input placeholder="搜索组件、截图、应用" v-model="searchTerm" @keydown.enter="handleEnter">
          <div class="searchbtn" @click="handleSearch">
            <img src="../assets/images/icon_sousuo_btn.svg">
            <p>搜索</p>
          </div>
    </div>
    <!-- 搜索推荐词 -->
    <div class="tags">
      <span
      v-for="item in hotSearch.slice(0, 8)"
      :key="item._id"
      @click="searchByKeyword(item.searchkeyword)">
      {{ item.searchkeyword }}
      </span>
    </div>

    <!-- 4个活动广告区 -->
    <div class="adbox">
      <div class="list"
        v-for="item in hoemAd.slice(0, 4)"
        :key="item._id">
          <a :href="item.link" target="_blank"><img :src="item.image"></a>
      </div>
    </div>
  </div>
</div>

<!-- 置顶组件模块 -->
<div class="widgetbox">
  <h1>丰富的UI组件、模板、素材、元素</h1>
  <div class="homeloading" v-if="loading">
    <img src="../assets/images/Loader.gif">
  </div>
  <div class="widget" v-if="!loading">
      <div class="widget_item"
      v-for="item in widgetTop.slice(0, 27)"
      :key="item._id"
      @click="showImage(item._id)">
        <img :src="item.image">
    </div>
  </div>
</div>

<!-- 公共底部栏组件 -->
<FooterBareslint />
</template>

<script>
import { useRouter } from 'vue-router'
import { get, post } from '../utils/request'
import FooterBareslint from '../components/FooterBareslint.vue'
import TopNav from '../components/TopNav.vue'
import { useStore } from 'vuex'
import { fetchTotalCount } from '../utils/utils'
import { ref, onMounted, watchEffect, provide } from 'vue'
import { useUserEffect } from '@/store/modules/useUserEffect'
import WidgetPopup from '../components/WidgetPopup.vue'

export default {
  name: 'HomeView',
  components: { FooterBareslint, TopNav, WidgetPopup },
  setup () {
    const { userInfo, getUserInfo } = useUserEffect()
    const store = useStore()
    const searchTerm = ref('') // 输入框值
    const router = useRouter()
    const hoemAd = ref([]) // 首页广告
    const widgetTop = ref([]) // 置顶组件
    const hotSearch = ref([]) // 热搜词
    const hotSearchword = [] // 推荐词
    const loading = ref(false) // 加载状态
    const currentImageData = ref(null) // 弹窗大图
    const showWidget = ref(false) // 组件弹窗
    const similarWidget = ref([]) // 相似组件数据
    const isCollected = ref(false) // 收藏状态
    provide('isCollected', isCollected) // 共享收藏状态给子组件
    const CACHE_EXPIRATION_TIME = 12 * 60 * 60 * 1000 // 12小时，以毫秒为单位
    const searchHistoryKey = 'searchHistory'

    // 登录状态----------------------------
    const isLoggedIn = () => {
      return localStorage.getItem('isLogin') === 'true'
    }

    onMounted(() => {
      getWidgetTop()
      getHomeAd()
      getHotSearch()
      // 监听 userInfo._id 的变化在执行fetchTotalCount
      watchEffect(() => {
        const userId = userInfo._id
        if (userId) {
          fetchTotalCount(userInfo._id, store)
        }
      })
    })

    // 首页广告-----------------------------------
    const getHomeAd = async () => {
      const result = await get('/api/homead')
      if (result?.errno === 0 && result?.data?.length) {
        hoemAd.value = result.data
      }
    }

    // 热搜词-----------------------------------
    const getHotSearch = async () => {
      const result = await get('/api/hotsearch')
      if (result?.errno === 0 && result?.data?.length) {
        hotSearch.value = result.data
      }
    }

    // 置顶组件-----------------------------------
    const getWidgetTop = async () => {
      loading.value = false
      try {
        const cachedData = localStorage.getItem('widgetTopData')
        if (cachedData) {
          const { timestamp, data } = JSON.parse(cachedData)
          if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
            // 数据未过期，使用缓存数据
            widgetTop.value = data
            return
          }
        }
        // 缓存数据过期或不存在，发送请求获取新数据
        const result = await get('/api/widget/top')
        if (result?.errno === 0 && result?.data?.length) {
          loading.value = false
          widgetTop.value = result.data
          // 将新数据保存到本地存储中，并记录时间戳
          const newData = { timestamp: Date.now(), data: result.data }
          localStorage.setItem('widgetTopData', JSON.stringify(newData))
        }
      } catch (error) {
        console.error('获取数据失败：', error)
      }
    }

    // 搜索组件--------------------------------------
    const search = (term) => {
      router.push({ name: 'search', query: { type: 'widget', q: term } })
    }
    const handleSearch = () => {
      const input = document.querySelector('input') // 获取输入框
      if (input) {
        input.blur() // 让输入框失去焦点
      }
      const searchKeyword = searchTerm.value.trim()
      if (searchKeyword !== '') {
        // 获取历史搜索记录
        let history = localStorage.getItem(searchHistoryKey)
        history = history ? JSON.parse(history) : []
        history.unshift(searchKeyword) // 添加新的搜索关键词到历史记录

        // 限制搜索记录长度
        if (history.length > 20) {
          history = history.slice(0, 20)
        }

        // 存储到本地缓存
        localStorage.setItem(searchHistoryKey, JSON.stringify(history))
        search(searchTerm.value) // 调用搜索方法，并传入搜索关键词
        // searchHistory.value = history // 更新搜索历史记录
      }
    }
    const handleEnter = (event) => {
      if (event.key === 'Enter') {
        search(searchTerm.value)
      }
    }
    // 推荐词搜索-----------------------------------
    const searchByKeyword = (keyword) => {
      router.push({ name: 'search', query: { type: 'widget', q: keyword } })
    }

    // 获取单个组件逻辑--------------------------------------------------
    const showImage = async (id) => {
      if (isLoggedIn()) {
        const userId = userInfo._id
        isCollected.value = await checkIfCollected(userId, id)
      }
      try {
        const result = await get(`/api/widget/${id}`)
        if (result?.errno === 0 && result?.data) {
          currentImageData.value = result.data // 更新当前图片数据
          enterWidgetPopup() // 显示图片弹窗
          getWidgetByTags(id) // 按标签查询相似组件
        } else {
          console.error('获取失败:', result?.errmsg || '未知错误')
        }
      } catch (error) {
        console.error('获取失败:', error.message)
      }
    }

    // 按标签查询相似组件-------------------------------------------
    const getWidgetByTags = async (id) => {
      const tags = currentImageData.value.tags
      const response = await post('/api/widget/search/tags', { tags, id })
      if (response?.errno === 0 && response?.data) {
        similarWidget.value = response.data
      }
    }

    // 检查当前数据是否已收藏-------------------------------------------
    const checkIfCollected = async (userId, itemId) => {
      try {
        const response = await get(`/api/collect/check/${userId}/${itemId}`)
        if (response?.errno === 0 && response?.data) {
          return true
        }
      } catch (error) {
        console.error('检查是否收藏失败:', error.message)
      }
      return false
    }

    // 显示图片弹窗-------------------------------------
    const enterWidgetPopup = () => {
      showWidget.value = true
      if (showWidget.value) {
        document.body.style.overflow = 'hidden'
      } else {
        // 隐藏子组件后恢复滚动
        document.body.style.overflow = 'auto'
      }
    }

    // 隐藏弹窗-------------------------------------
    const hideWidget = async () => {
      showWidget.value = false // 子组件点击按钮隐藏后的回调
      document.body.style.overflow = 'auto' // 恢复滚动
    }

    return {
      hotSearch,
      hoemAd,
      getHotSearch,
      getHomeAd,
      widgetTop,
      searchTerm,
      search,
      handleEnter,
      searchByKeyword,
      hotSearchword,
      getWidgetTop,
      getUserInfo,
      currentImageData,
      showWidget,
      similarWidget,
      isCollected,
      loading,
      showImage,
      isLoggedIn,
      checkIfCollected,
      enterWidgetPopup,
      hideWidget,
      CACHE_EXPIRATION_TIME,
      handleSearch,
      searchHistoryKey
    }
  }
}
</script>

<style lang="scss">
@import '../style/index.scss'
</style>
