import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueLazyload from 'vue-lazyload'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

function setupAnalytics () {
  // eslint-disable-next-line
  var _hmt = _hmt || [];
  (function () {
    // 添加百度统计代码
    const hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?6d9c0173a9b9116f23d20e21a4e7f5ae'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)

    // 添加Google Analytics代码
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-EX5X32FGFB'
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    // eslint-disable-next-line
    function gtag () { dataLayer.push(arguments) }
    gtag('js', new Date())

    gtag('config', 'G-EX5X32FGFB')
  })()

  router.afterEach((to, from) => {
    if (window._hmt && typeof window._hmt.push === 'function') {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  })
}

const app = createApp(App)

setupAnalytics() // 在 Vue 实例创建时调用统计代码

// 全局注册 Element Plus 图标
Object.entries(ElementPlusIconsVue).forEach(([key, component]) => {
  app.component(key, component)
})

app.use(store)
app.use(router)
app.use(ElementPlus, { zIndex: 999999999999 })
app.use(VueLazyload, {
  preLoad: 1.2, // 开始预加载图片的高度比例
  error: '', // 图片加载失败时显示的图片
  loading: '', // 图片加载过程中显示的图片
  attempt: 1 // 尝试加载图片的次数
})

app.mount('#app')
