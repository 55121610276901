<template>
    <div class="widget_large_main" @click.stop v-if="imageData">
      <!-- <p @click="hideSelf">[esc关闭]</p> -->
      <img src="../assets/images/icon_white_close.svg" @click="hideSelf">
      <div class="widget_large_img">
        <!-- 复制后提示 -->
        <div class="copy_success_mask" v-if="copySuccess">
          <span class="hide_ad" @click="hideMarketAd">关闭</span>
          <div class="tools_link">
            <img src="../assets/images/icon_replicated.svg" />
            <p>组件已复制，请返回设计工具粘贴</p>
            <div class="widget_tools_item">
              <img src="../assets/images/icon_sketch.svg"/>
              <img src="../assets/images/icon_figma.svg"/>
              <img src="../assets/images/icon_jsdesign.svg"/>
              <img src="../assets/images/icon_mastergo.svg"/>
              <img src="../assets/images/icon_pixso.svg"/>
              <img src="../assets/images/icon_adobexd.svg"/>
              <img src="../assets/images/icon_adobeai.svg"/>
            </div>
          </div>

          <!-- 广告 -->
          <div class="copy_success_ad">
            <div class="ad_box" @click="openAdLink(randomMarketAd.link)">
              <div class="ad_store_img">
                <img :src="randomMarketAd.image" />
              </div>
              <div class="ad_store_content">
                <span class="ad_store_title">{{ randomMarketAd.title }}</span>
                <span class="ad_store_price">到手价￥{{ randomMarketAd.price }}元</span>
                <button @click="copyMarketAdCode(randomMarketAd.code)" @click.stop>复制淘口令</button>
              </div>
              <span class="ad_identifier">由淘宝提供的广告</span>
            </div>
            <span>* UI大师仅提供技术支持，请自行甄别广告的商品质量</span>
          </div>
        </div>
        <!-- 组件封面 -->
        <img :src="imageData.image">
      </div>
      <div class="widget_large_content">
        <!-- 右侧头部信息 -->
        <div class="widget_popup_top">
          <div class="widget_price_box">
            <div
              v-if="imageData.price > 0"
              class="widget_popup_price">
                {{ imageData.price }}
                <img src="../assets/images/icon_candy.svg">
            </div>
            <div v-else class="widget_popup_price">
              免费
            </div>
            <div class="collectbox">
              <!-- <img title="举报/投诉" src="../assets/images/icon_report.svg"> -->
              <img title="分享组件" @click="copyUrl(imageData)" src="../assets/images/icon_share.svg">
              <img title="取消收藏" @click="toggleCollect(imageData._id)" @click.stop v-if="isCollected" src="../assets/images/icon_collect_hover.svg">
              <img title="收藏" @click="toggleCollect(imageData._id)" @click.stop v-else src="../assets/images/icon_collect.svg">
            </div>
          </div>
          <div class="widget_popup_title">
            <span v-if="imageData.price > 0 && imageData.author">说明：复制该组件消耗的U糖归创作者所有</span>
            <router-link :to="{ name: 'widgetdetails', params: { id: imageData._id } }" target="_blank">
              <h1 title="详情">{{ imageData.title }}</h1>
            </router-link>
          </div>
          <div class="widget_popup_btn">
            <button
              class="large_copy_btn"
              v-if="(!imageData.author || imageData.author._id !== userInfo._id) && imageData.price > 0"
              @click="handleCopyCode(imageData)"
              :disabled="isDisabled"
              :class="{ 'button-disabled': isDisabled }"
              @click.stop>复制组件 {{ imageData.price }}🍬
            </button>
            <button
              class="large_copy_btn"
              v-else
              @click="handleCopyCode(imageData)"
              :disabled="isDisabled"
              :class="{ 'button-disabled': isDisabled }"
              @click.stop>复制组件
            </button>
            <button class="large_share_btn" @click="editWidget(imageData._id)">编辑组件</button>
          </div>
          <div v-if="imageData.author" class="widget_popup_author">
            <router-link :to="{ name: 'userspace', params: { id: imageData.author._id } }" target="_blank">
              <div class="widget_popup_avatar"><img :src="imageData.author.imgUrl"></div>
              <span class="widget_author_name">{{ imageData.author.nickname }}</span>
              <span v-if="imageData.author.about" class="widget_author_signature">{{ imageData.author.about }}</span>
              <span v-else class="widget_author_signature">神秘的旅行者</span>
            </router-link>
          </div>
          <div v-else class="widget_popup_author">
            <div class="widget_popup_avatar"><img src="../assets/images/img_about_logo.png"></div>
            <span class="widget_author_name">UI大师官方</span>
            <span class="widget_author_signature">丰富的UI组件库，复制粘贴使用</span>
          </div>
        </div>
          <!-- 组件标签 -->
          <!-- 在 tags 数组长度大于 0 且数组中的第一个元素不是空字符串时 -->
          <div class="widgettagsbox" v-if="imageData.tags.length > 0 && imageData.tags[0] !== ''">
            <span>组件标签</span>
            <div class="tagsbox">
              <div
                @click="searchByKeyword(tag)"
                class="tags_item"
                v-for="tag in imageData.tags"
                :key="tag">{{ tag }}
              </div>
            </div>
          </div>
          <!-- 相似组件 -->
          <div class="similarmain" v-if="similarData.length > 0" >
            <span>相似组件 ( {{ similarData.length }} )</span>
            <div class="similarbox">
              <div
                class="similar_item"
                v-for="(item, index) in similarData"
                :key="index"
                @click="openSimilarWidget(item._id)">
                <img :src="item.image">
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineEmits, inject, provide, defineProps, watch } from 'vue'
import { post, del, get } from '../utils/request'
import { ElMessage } from 'element-plus'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { fetchTotalCount } from '../utils/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const props = defineProps({
  imageData: Object,
  similarData: Object
})
const router = useRouter() // 使用 useRouter 获取路由实例
const store = useStore()
const { userInfo } = useUserEffect()
const currentCollected = ref(false) // 收藏状态
const isCollected = inject('isCollected', ref(false)) // 接收父组件收藏状态
const container = ref(null) // 弹窗图片返回顶部
provide('container', container)
// const route = useRoute()
const updateCandy = ref(0) // 加载顶部U糖初始值
const emit = defineEmits(['editWidget', 'hide', 'similarWidget', 'update-is-collected'])
const isDisabled = ref(false) // 允许点击状态
const randomMarketAd = ref([]) // 随机广告
const copySuccess = ref(false) // 复制后内容状态

// 点击标签搜索
const searchByKeyword = (keyword) => {
  const route = {
    name: 'search',
    query: { type: 'widget', q: keyword }
  }
  document.body.style.overflow = 'auto'
  // 检查当前路由名称
  const currentRouteName = router.currentRoute.value.name

  if (['app', 'app-hot', 'screen', 'app-all', 'appdetails', 'read'].includes(currentRouteName)) {
    // 获取路由对象的完整 URL
    const url = router.resolve(route).href
    // 在新标签页中打开该 URL
    window.open(url, '_blank')
  } else {
    // 默认跳转
    router.push(route)
    hideSelf()
  }
}

// 在收藏页面中不显示相似组件
// const shouldShowDiv = computed(() => {
//   return !route.path.includes('/collect')
// })

// 通知父组件显示编辑弹窗
const editWidget = () => {
  emit('editWidget', props.imageData._id)
}

// 关闭弹窗
const handleKeyUp = (event) => {
  if (event.key === 'Escape') {
    hideSelf()
  }
}

// 通知父组件关闭弹窗
const hideSelf = () => {
  copySuccess.value = false
  emit('hide')
}

// 收藏和取消组件
const toggleCollect = async (itemId) => {
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage({
      message: '请先登录！',
      type: 'warning'
    })
    return
  }
  const userId = userInfo._id
  currentCollected.value = await checkIfCollected(userId, itemId)
  let result
  if (currentCollected.value) {
    // 取消收藏逻辑
    result = await removeCollect(userId, itemId)
    fetchTotalCount(userInfo._id, store)
    ElMessage.success('已取消收藏！')
    isCollected.value = false
  } else {
    // 添加收藏逻辑
    result = await post('/api/collect', { userId, widgetId: itemId })
    fetchTotalCount(userInfo._id, store)
    ElMessage.success('收藏成功！')
    isCollected.value = true
  }
  // 发射事件通知父组件，保持参数一致
  emit('update-is-collected', itemId, isCollected.value)
  if (result?.errno === 0) {
    return true
  }
}
// 取消收藏组件方法
const removeCollect = async (userId, itemId) => {
  try {
    const response = await del(`/api/collect/${userId}/${itemId}`)
    if (response.data.errno === 0) {
      return response.data // 返回成功的响应数据
    }
  } catch (error) {
    console.error('取消收藏失败:', error)
    throw error
  }
}

// 检查当前数据是否已收藏
const checkIfCollected = async (userId, itemId) => {
  try {
    const response = await get(`/api/collect/check/${userId}/${itemId}`)
    if (response?.errno === 0 && response?.data) {
      return true
    }
  } catch (error) {
    console.error('检查是否收藏失败:', error.message)
  }
  return false
}

// 点击相似组件传递id通知父组件执行弹窗函数
const openSimilarWidget = (id) => {
  copySuccess.value = false
  emit('similarWidget', id)
  // 弹窗图片返回顶部
  if (container.value) {
    container.value.scrollTop = 0
  }
}

// 页面加载时执行
onMounted(() => {
  window.addEventListener('keyup', handleKeyUp)
})
onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeyUp)
})

// 重新加载顶部U糖数据
const reloadCandy = () => {
  updateCandy.value = Math.floor(Math.random() * 100) // 随机数变化更新组件
}

// 记录U糖变化
const createCandyLogBase = async ({ userId, widgetId, type, copyType, candys }) => {
  const currentDate = new Date() // 获取中国北京时间（UTC+8）
  const offset = 8 * 60 // UTC+8 的分钟数
  const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
    .toISOString()
    .replace('Z', '+08:00')
  try {
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('widgetId', widgetId)
    formData.append('type', type)
    formData.append('copyType', copyType)
    formData.append('candys', candys)
    formData.append('addTime', addTime)
    const result = await post('/api/candyLog', formData)
    if (result?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}
// 记录用户U糖变化
const createCandyLog = async (imageData) => {
  const userId = userInfo._id
  const widgetId = imageData._id
  const type = '复制组件'
  const copyType = false
  const candys = imageData.price
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}
// 记录作者U糖变化
const createCandyLogInauthor = async (imageData) => {
  const authorId = imageData.author?._id
  const widgetId = imageData._id
  const type = '组件被复制'
  const copyType = true
  const candys = imageData.price
  await createCandyLogBase({ userId: authorId, widgetId, type, copyType, candys })
}

// 更新作者U糖
const upCandyInauthor = async (imageData) => {
  try {
    const authorId = imageData.author?._id
    const candyResult = await post(`/api/user/add-candys/${authorId}`, { candys: imageData.price })
    if (candyResult?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}

// 限制频繁复制
const handleCopyCode = async (imageData) => {
  if (isDisabled.value) return

  isDisabled.value = true
  await copyCodeLarge(imageData) // 执行相关逻辑

  // *秒后重新允许点击
  setTimeout(() => {
    isDisabled.value = false
  }, 2000)
}

// 复制组件逻辑
const copyCodeLarge = async (imageData) => {
  getRandomMarketAd() // 获取广告
  const userId = userInfo._id
  const authorId = imageData.author?._id // 使用可选链操作符检查 author 是否存在

  // 复制代码到剪贴板的通用函数
  const copyToClipboard = (code) => {
    const textarea = document.createElement('textarea')
    textarea.value = code
    textarea.style.position = 'fixed' // 避免在页面上滚动
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  //  监测是否为Safari浏览器
  function isSafari () {
    const ua = navigator.userAgent.toLowerCase()
    return ua.includes('safari') && !ua.includes('chrome')
  }
  // 检测是否是 Safari 浏览器
  if (isSafari()) {
    ElMessage.error('当前浏览器暂不支持复制组件！')
    return
  }

  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage.warning('请先登录！每天登录可获得10U糖')
    return
  }

  try {
    // 如果用户是作者，直接复制代码
    if (userId === authorId || imageData.price === 0) {
      const result = await get(`/api/widget/code/${imageData._id}`)
      if (result?.errno === 0) {
        copyToClipboard(result.data.code)
        await createCandyLogInauthor(imageData) // 生成作者记录
        await createCandyLog(imageData) // 生成用户记录
        copySuccess.value = true // 显示广告
      } else {
        ElMessage.error('复制失败!')
      }
    } else {
      // 如果没有作者信息或作者不是当前用户，判断U糖数量
      if (userInfo.candys < imageData.price) {
        ElMessage.error('U糖不足！每天登录可获得10U糖')
        return
      }

      // 扣除用户U糖
      const candyResult = await post(`/api/user/candys/${userId}`, { candys: imageData.price })
      if (candyResult?.errno === 0) {
        await createCandyLogInauthor(imageData) // 生成作者记录
        await createCandyLog(imageData) // 生成用户记录
        await upCandyInauthor(imageData)
        copySuccess.value = true // 显示广告
        reloadCandy() // 重新加载顶部U糖数据
      } else {
        ElMessage.error('扣除积分失败！')
        return
      }

      // 复制组件代码
      const result = await get(`/api/widget/code/${imageData._id}`)
      if (result?.errno === 0) {
        copyToClipboard(result.data.code)
      } else {
        ElMessage.error('复制失败!')
      }
    }
  } catch (error) {
    ElMessage.error('复制组件异常，请稍后重试!')
  }
}

// 分享组件
const copyUrl = (imageData) => {
  const id = imageData._id
  // 生成路由路径
  const url = router.resolve({ name: 'widgetdetails', params: { id } }).href

  // 自定义的文本
  const customText = '这张来自「UI大师」的组件素材不错哦，点击链接查看 '
  // 拼接 URL 和自定义文本
  const fullText = `${customText}${window.location.origin}${url}`

  // 复制到剪贴板
  navigator.clipboard.writeText(fullText).then(() => {
    ElMessage.success('链接复制成功，可分享给好友了~')
  }).catch((err) => {
    ElMessage.error('复制失败!')
    console.error('复制失败:', err)
  })
}

// 获取1条广告
const getRandomMarketAd = async () => {
  try {
    const result = await get('/api/market-ad/random')
    if (result?.errno === 0 && result?.data) {
      // 为广告添加 isAd 标识
      randomMarketAd.value = {
        ...result.data,
        isAd: true // 添加广告标识
      }
    }
  } catch (error) {
    console.error(error)
  }
}

// 复制口令
const copyMarketAdCode = async (code) => {
  try {
    await navigator.clipboard.writeText(code)
    ElMessage({
      showClose: true,
      message: '淘口令已复制，打开淘宝App即可自动识别！',
      type: 'success',
      duration: 5000 // 0为不自动关闭，默认值3000
    })
  } catch (error) {
    console.error('复制失败:', error)
    ElMessage.error('复制失败，请重试')
  }
}

// 跳转广告
const openAdLink = (link) => {
  window.open(link, '_blank') // 在新窗口中打开对应的链接
}

// 关闭广告
const hideMarketAd = () => {
  copySuccess.value = false
}

// 监听父组件的组件信息来隐藏广告
watch(
  () => props.imageData,
  (newVal) => {
    if (newVal) {
      hideMarketAd()
    }
  },
  { immediate: true }
)

</script>

<style lang="scss" scoped>
@import "../style/widgetpopup.scss";
</style>
