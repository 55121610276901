import { get } from '../utils/request'

// 请求收藏总数
export const fetchTotalCount = async (userId, store) => {
  try {
    const result = await get(`/api/collect/${userId}`)
    if (result?.errno === 0 && result?.data) {
      const totalCount = result.data.totalCount || 0
      store.commit('updateTotalCount', totalCount)
    }
  } catch (error) {
    console.error('获取totalCount失败:', error.message)
    throw error
  }
}
