<template>
    <div class="tools_menu">
        <router-link :to="{ name: 'poster'}">AI生成海报</router-link>
        <router-link :to="{ name: 'analysis'}">AI需求分析</router-link>
        <router-link :to="{ name: 'generator'}">UI生成</router-link>
        <router-link :to="{ name: 'table' }">表格生成</router-link>
    </div>
</template>

<script>

export default {
  name: 'ToolsPopup'
}
</script>

<style lang="scss">
.tools_menu {
    width: .9rem;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.08);
    border-radius: .1rem;
    flex-wrap: wrap;
    padding-left: .2rem;
    overflow: hidden;
}

.tools_menu a {
    height: .4rem;
    line-height: .36rem;
    font-size: .14rem;
    color: #666;
    width: 100%;
}
.tools_menu a:hover {
    color: #11C2FB;
    cursor: pointer;
    background: linear-gradient(270deg, #E7F9FF 0%, #FFFFFF 100%);
}
</style>
