import { createStore } from 'vuex'

export default createStore({
  state: {
    totalCount: 0 // 收藏总数
  },
  getters: {
    // 在需要访问totalCount的组件中使用该getter
    getTotalCount: state => state.totalCount
  },
  mutations: {
    // 全局共享收藏总数
    updateTotalCount (state, newTotalCount) {
      state.totalCount = newTotalCount
    }
  },
  actions: {
  },
  modules: {
  }
})
