<!-- 底部公共区 -->
<template>
  <div class="footerbox">
    <div class="footer">
      <div class="profile">
        <div class="footer_logo">
          <img src="../assets/images/logo_white.svg" />
        </div>
        <span>UI大师-比AI更懂你的需求</span>
        <p>
          通过丰富的UI组件，为企业和个人节省80%的设计时间，快速完成重复低效的设计工作，<br />进入全新的UI设计时代。
        </p>
      </div>
      <div class="follow">
        <div class="wechat">
          <span>商务合作</span>
          <img src="../assets/images/me_wechatcode.png" />
        </div>
        <div class="wechat">
          <span>小助手微信</span>
          <img src="../assets/images/wechatcode.png" />
        </div>
        <div class="wechat">
          <span>UI大师公众号</span>
          <img src="../assets/images/gh_qrcode.jpg" />
        </div>
      </div>
    </div>
    <div class="contact">
      <p>
        ©2023 - 2024 深圳智在向前科技有限公司 All Rights Reserved<br />
        深圳市宝安区宝安大道旭生大厦5层<br />
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022095797号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBareslint'
}
</script>

<style lang="scss">
@import '../style/footerbar.scss'
</style>
