<template>
    <div class="widget_large_main" @click.stop v-if="imageData">
      <img src="../../assets/images/icon_white_close.svg" @click="hideSelf">
      <div class="edit_widget_img">
        <img v-if="pngImage" :src="pngImage">
      </div>
      <div class="edit_widget_content">
        <!-- 顶部标题 -->
        <div class="edit_widget_title">
          <span>修改组件文本</span>
          <div class="edit_widget_title_btn">
            <button
              v-if="(!props.imageData.author || props.imageData.author._id !== userInfo._id) && props.imageData.price > 0"
              class="download_svg"
              @click="downloadToSvg">
              导出SVG文件 {{ props.imageData.price }}🍬
            </button>
            <button
              v-else
              class="download_svg"
              @click="downloadToSvg">
              导出SVG文件
            </button>

            <button
              v-if="(!props.imageData.author || props.imageData.author._id !== userInfo._id) && props.imageData.price > 0"
              class="copy_widget"
              @click="copySvgContent">
              复制组件 {{ props.imageData.price }}🍬
            </button>
            <button
              v-else
              class="copy_widget"
              @click="copySvgContent">
              复制组件
            </button>
          </div>
        </div>

        <!-- 输入框 -->
        <div
          class="edit_widget_input"
          v-if="tspanContents.length > 0">
          <input
            v-for="(tspan, index) in tspanContents"
            :key="index"
            placeholder="输入文本内容"
            v-model="tspanContents[index]"
            :id="'tspan-' + index">
        </div>

        <div v-else class="edit_widget_input_nullimg">
          <img src="../../assets/images/null.svg">
          <span>未识别出该组件有可编辑的文本<br>可能文本被轮廓化或为位图</span>
        </div>

        <!-- 底部 -->
        <div class="edit_widget_bottom" v-if="tspanContents.length > 0">
          <span @click="resetSvgContent">恢复默认</span>
          <div class="edit_widget_bottom_btn">
            <button class="edit_widget_cancel" @click="hideSelf">取消</button>
            <button class="edit_widget_confirm" @click="saveTspanContent">修改</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watchEffect, defineProps } from 'vue'
import { post } from '../../utils/request'
import { ElMessage } from 'element-plus'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { fetchTotalCount } from '../../utils/utils'
import { useStore } from 'vuex'

const store = useStore()
const { emit } = getCurrentInstance()
const { userInfo } = useUserEffect()
const updateCandy = ref(0) // 加载顶部U糖初始值
const props = defineProps({
  imageData: Object,
  imageDataCode: String
})
const newSvgContent = ref('') // 存储svg代码容器
const pngImage = ref(null) // 生成图片
const tspanContents = ref([]) // 存储tspan内容

// 关闭弹窗
const hideSelf = () => {
  emit('hideEdit')
}

// 页面加载时执行
onMounted(() => {
  // 监听 userInfo._id 的变化在执行fetchTotalCount
  watchEffect(() => {
    const userId = userInfo._id
    if (userId) {
      fetchTotalCount(userInfo._id, store)
    }
  })
})

// 动态监听传递的svg代码值
watchEffect(() => {
  if (props.imageDataCode) {
    extractTspanContents() // 提取svg内容
    generatePng(props.imageDataCode) // 生成图片
  }
})

// 重新加载顶部U糖数据
const reloadCandy = () => {
  updateCandy.value = Math.floor(Math.random() * 100) // 随机数变化更新组件
}

// 记录U糖变化
const createCandyLogBase = async ({ userId, widgetId, type, copyType, candys }) => {
  const currentDate = new Date() // 获取中国北京时间（UTC+8）
  const offset = 8 * 60 // UTC+8 的分钟数
  const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
    .toISOString()
    .replace('Z', '+08:00')
  try {
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('widgetId', widgetId)
    formData.append('type', type)
    formData.append('copyType', copyType)
    formData.append('candys', candys)
    formData.append('addTime', addTime)
    const result = await post('/api/candyLog', formData)
    if (result?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}
// 生成消耗U糖复制记录
const createCandyLog = async (imageData) => {
  const userId = userInfo._id
  const widgetId = imageData._id
  const type = '复制组件'
  const copyType = false
  const candys = imageData.price
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}
// 生成免费复制记录
const createFreeCandyLog = async (imageData) => {
  const userId = userInfo._id
  const widgetId = imageData._id
  const type = '复制组件'
  const copyType = false
  const candys = 0
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}

// 生成消耗U糖下载记录
const generateCandyLog = async (imageData) => {
  const userId = userInfo._id
  const widgetId = imageData._id
  const type = '导出SVG文件'
  const copyType = false
  const candys = imageData.price
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}
// 生成免费下载记录
const generateFreeCandyLog = async (imageData) => {
  const userId = userInfo._id
  const widgetId = imageData._id
  const type = '导出SVG文件'
  const copyType = false
  const candys = 0
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}

// 生成组件被复制记录
const createCandyLogInauthor = async (imageData) => {
  const authorId = imageData.author?._id
  const widgetId = imageData._id
  const type = '组件被复制'
  const copyType = true
  const candys = imageData.price
  await createCandyLogBase({ userId: authorId, widgetId, type, copyType, candys })
}

// 生成组件被下载记录
const generateCandyLogInauthor = async (imageData) => {
  const authorId = imageData.author?._id
  const widgetId = imageData._id
  const type = '组件被下载'
  const copyType = true
  const candys = imageData.price
  await createCandyLogBase({ userId: authorId, widgetId, type, copyType, candys })
}

// 更新作者U糖
const upCandyInauthor = async (imageData) => {
  try {
    const authorId = imageData.author?._id
    const candyResult = await post(`/api/user/add-candys/${authorId}`, { candys: imageData.price })
    if (candyResult?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}

// 提取内容
const extractTspanContents = () => {
  // 将SVG内容转为DOM对象
  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(props.imageDataCode, 'image/svg+xml')

  // 查找所有 <tspan> 元素
  const tspanElements = svgDoc.querySelectorAll('tspan')

  // 提取 <tspan> 的内容，使用 innerHTML 保留实体字符
  tspanContents.value = Array.from(tspanElements).map(
    (tspan) => tspan.innerHTML
  )
}

// 保存内容
const saveTspanContent = () => {
  // 将修改后的 tspan 内容保存回 SVG
  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(props.imageDataCode, 'image/svg+xml')

  // 查找所有 <tspan> 元素
  const tspanElements = svgDoc.querySelectorAll('tspan')

  // 更新 tspan 的文本内容，使用 innerHTML 以保留实体字符
  tspanContents.value.forEach((content, index) => {
    tspanElements[index].innerHTML = content
  })

  // 将更新后的SVG重新序列化为字符串
  const serializer = new XMLSerializer()
  newSvgContent.value = serializer.serializeToString(svgDoc)
  ElMessage.success('文本已修改')
  // 生成新的 PNG 图片
  generatePng(newSvgContent.value)
}

// 生成图片
const generatePng = (svgContent) => {
  const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' })
  const url = URL.createObjectURL(svgBlob)
  const img = new Image()

  img.onload = () => {
    const canvas = document.createElement('canvas')
    // 将 canvas 的宽高设置为原图的两倍
    canvas.width = img.width * 2
    canvas.height = img.height * 2
    const ctx = canvas.getContext('2d')

    // 使用 drawImage 的额外参数，将图像按 2 倍大小绘制到 canvas 上
    ctx.drawImage(img, 0, 0, img.width * 2, img.height * 2)

    // 将 canvas 转换为 PNG 图片
    pngImage.value = canvas.toDataURL('image/png')
    URL.revokeObjectURL(url) // 释放 URL 对象
  }
  img.src = url
}

// 下载
const downloadSvg = () => {
  const contentToDownload = newSvgContent.value && newSvgContent.value !== props.imageDataCode
    ? newSvgContent.value
    : props.imageDataCode

  const svgBlob = new Blob([contentToDownload], { type: 'image/svg+xml' })
  const url = URL.createObjectURL(svgBlob)
  const a = document.createElement('a')
  const svgFileName = props.imageData.title // 获取组件标题
  a.href = url
  a.download = svgFileName // 将组件标题作为下载文件名
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url) // 释放 URL 对象
}

// 触发下载
const downloadToSvg = async () => {
  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage.warning('请先登录！每天登录可获得10U糖')
    return
  }

  const userId = userInfo._id
  const authorId = props.imageData.author?._id // 使用可选链操作符检查 author 是否存在
  ElMessage({
    showClose: true,
    message: '已请求下载，注意保存文件！',
    type: 'success',
    duration: 5000 // 0为不自动关闭，默认值3000
  })

  try {
    // 如果用户是作者，直接复制代码
    if (userId === authorId || props.imageData.price === 0) {
      downloadSvg() // 下载文件函数
      await generateFreeCandyLog(props.imageData)
      reloadCandy() // 重新加载顶部U糖数据
    } else {
      // 如果没有作者信息或作者不是当前用户，判断U糖数量
      if (userInfo.candys < props.imageData.price) {
        ElMessage.error('U糖不足！每天登录可获得10U糖')
        return
      }
      // 扣除用户U糖
      const candyResult = await post(`/api/user/candys/${userId}`, { candys: props.imageData.price })
      if (candyResult?.errno === 0) {
        downloadSvg() // 下载文件函数
        await generateCandyLogInauthor(props.imageData) // 组件被下载记录
        await generateCandyLog(props.imageData) // 消耗U糖记录
        await upCandyInauthor(props.imageData) // 作者U糖增加
        reloadCandy() // 重新加载顶部U糖数据
      } else {
        ElMessage.error('下载失败！')
        return
      }
    }
  } catch (err) {
    ElMessage.error('下载失败！')
  }
}

// 重置默认
const resetSvgContent = () => {
  extractTspanContents() // 提取原始的 tspan 内容，更新输入框
  newSvgContent.value = props.imageDataCode
  ElMessage.success('文本已恢复默认')
  generatePng(props.imageDataCode) // 生成图片
}

// 复制
const copySvgContent = async () => {
  //  监测是否为Safari浏览器
  function isSafari () {
    const ua = navigator.userAgent.toLowerCase()
    return ua.includes('safari') && !ua.includes('chrome')
  }
  // 检测是否是 Safari 浏览器
  if (isSafari()) {
    ElMessage.error('当前浏览器暂不支持复制组件！')
    return
  }

  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage.warning('请先登录！每天登录可获得10U糖')
    return
  }

  const userId = userInfo._id
  const authorId = props.imageData.author?._id // 使用可选链操作符检查 author 是否存在

  // 判断内容是否修改来复制内容
  const contentToCopy = newSvgContent.value && newSvgContent.value !== props.imageDataCode
    ? newSvgContent.value
    : props.imageDataCode

  try {
    // 如果用户是作者，直接复制代码
    if (userId === authorId || props.imageData.price === 0) {
      await navigator.clipboard.writeText(contentToCopy)
      ElMessage.success('组件已复制，请返回设计工具粘贴！')
      await createFreeCandyLog(props.imageData)
      reloadCandy() // 重新加载顶部U糖数据
    } else {
      // 如果没有作者信息或作者不是当前用户，判断U糖数量
      if (userInfo.candys < props.imageData.price) {
        ElMessage.error('U糖不足！每天登录可获得10U糖')
        return
      }
      // 扣除用户U糖
      const candyResult = await post(`/api/user/candys/${userId}`, { candys: props.imageData.price })
      if (candyResult?.errno === 0) {
        await navigator.clipboard.writeText(contentToCopy)
        ElMessage.success('组件已复制，请返回设计工具粘贴！')
        await createCandyLogInauthor(props.imageData) // 组件被下载记录
        await createCandyLog(props.imageData) // 消耗U糖记录
        await upCandyInauthor(props.imageData) // 作者U糖增加
        reloadCandy() // 重新加载顶部U糖数据
      } else {
        ElMessage.error('复制失败！')
        return
      }
    }
  } catch (err) {
    ElMessage.error('复制失败！')
  }
}

</script>

<style lang="scss" scoped>
@import "../../style/widget/eidtwidgetpopup.scss";
</style>
