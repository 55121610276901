<template>
    <!-- 组件大图详情 -->
    <div class="widget_large_mask" v-show="showWidget" @click="hideWidget">
    <WidgetPopup
      :imageData="currentImageData"
      :similarData="similarWidget"
      v-show="showWidget"
      @similarWidget="showImage"
      @hide="hideWidget"/>
  </div>

<div class="topsearchbox" @click.stop>
    <div class="historybox">
      <h1>搜索历史</h1>
      <div class="clearhistory"
      @click="clearHistory"
      v-if="searchHistory.length > 0">
        <img src="../assets/images/icon_search_qk.svg">
      </div>
      <div class="historyitembox">
        <div class="historyitem" v-if="searchHistory.length > 0">
            <span v-for="(item, index) in searchHistory" :key="index" @click="handleSearch(item)">{{ item }}</span>
        </div>
        <div class="histor_nul" v-else>
            <img src="../assets/images/icon_histor_null.svg">
            <p>暂无历史记录</p>
        </div>
      </div>
    </div>
    <!-- 搜索推荐 -->
    <div class="recommendmain">
      <div class="recommend_titel">
        <h1>🔥精选推荐</h1>
      </div>
        <div class="recommendbox">
          <div
            class="listitem_img"
            v-for="item in hoemAd.slice(0, 4)"
            :key="item._id"
            >
            <a
              :href="item.link"
              target="_blank"
              >
              <img :src="item.image">
            </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { inject, getCurrentInstance, ref, provide, onMounted } from 'vue'
import { get, post } from '../utils/request'
import WidgetPopup from '../components/WidgetPopup.vue'
import { useUserEffect } from '@/store/modules/useUserEffect'

export default {
  name: 'SearchPopup',
  components: { WidgetPopup },
  emits: ['clearHistory', 'search'],

  setup () {
    const { userInfo, getUserInfo } = useUserEffect()
    const { emit } = getCurrentInstance()
    const searchHistory = inject('searchHistory', []) // 接收父组件搜索历史记录
    const isTopSearchBox = inject('isTopSearchBox', true)
    const mobile = ref([]) // 移动端列表数据
    const pc = ref([]) // PC端列表数据
    const element = ref([]) // element列表数据
    const template = ref([]) // template列表数据
    const currentImageData = ref(null) // 弹窗大图
    const showWidget = ref(false) // 组件弹窗
    const similarWidget = ref([]) // 相似组件数据
    const isCollected = ref(false) // 收藏状态
    provide('isCollected', isCollected) // 共享收藏状态给子组件
    const CACHE_EXPIRATION_TIME = 12 * 60 * 60 * 1000 // 12小时
    const hoemAd = ref([]) // 首页广告

    // 登录状态
    const isLoggedIn = () => {
      return localStorage.getItem('isLogin') === 'true'
    }

    // 首页广告
    const getHomeAd = async () => {
      const result = await get('/api/homead/ad')
      if (result?.errno === 0 && result?.data?.length) {
        hoemAd.value = result.data
      }
    }
    onMounted(() => {
      getHomeAd()
    })

    // 清空搜索历史记录
    const clearHistory = () => {
      searchHistory.value = [] // 清空搜索历史记录
      // ctx.searchHistory.length = 0
      emit('clear-history', [])
    }
    // 点击历史记录传递给父组件
    const handleSearch = (term) => {
      emit('search', term)
    }
    // 获取单个组件逻辑
    const showImage = async (id) => {
      if (isLoggedIn()) {
        const userId = userInfo._id
        isCollected.value = await checkIfCollected(userId, id)
      }
      try {
        const result = await get(`/api/widget/${id}`)
        if (result?.errno === 0 && result?.data) {
          currentImageData.value = result.data // 更新当前图片数据
          enterWidgetPopup() // 显示图片弹窗
          getWidgetByTags(id) // 按标签查询相似组件
        } else {
          console.error('获取失败:', result?.errmsg || '未知错误')
        }
      } catch (error) {
        console.error('获取失败:', error.message)
      }
    }

    // 按标签查询相似组件
    const getWidgetByTags = async (id) => {
      const tags = currentImageData.value.tags
      const response = await post('/api/widget/search/tags', { tags, id })
      if (response?.errno === 0 && response?.data) {
        similarWidget.value = response.data
      }
    }

    // 检查当前数据是否已收藏
    const checkIfCollected = async (userId, itemId) => {
      try {
        const response = await get(`/api/collect/check/${userId}/${itemId}`)
        if (response?.errno === 0 && response?.data) {
          return true
        }
      } catch (error) {
        console.error('检查是否收藏失败:', error.message)
      }
      return false
    }

    // 显示图片弹窗
    const enterWidgetPopup = () => {
      showWidget.value = true
      if (showWidget.value) {
        document.body.style.overflow = 'hidden'
      } else {
        // 隐藏子组件后恢复滚动
        document.body.style.overflow = 'auto'
      }
    }

    // 隐藏弹窗
    const hideWidget = async () => {
      showWidget.value = false // 子组件点击按钮隐藏后的回调
      document.body.style.overflow = 'auto' // 恢复滚动
    }

    return {
      searchHistory,
      clearHistory,
      handleSearch,
      showImage,
      hideWidget,
      enterWidgetPopup,
      checkIfCollected,
      currentImageData,
      getWidgetByTags,
      showWidget,
      userInfo,
      getUserInfo,
      isCollected,
      isTopSearchBox,
      similarWidget,
      mobile,
      pc,
      element,
      template,
      isLoggedIn,
      CACHE_EXPIRATION_TIME,
      getHomeAd,
      hoemAd
    }
  }
}

</script>

<style lang="scss">
@import '../style/searchpopup.scss'
</style>
