<!-- 组件页 -->
<template>
  <!-- 组件大图详情 -->
  <div class="widget_large_mask" v-show="showWidget" @click="hideWidget">
    <WidgetPopup
      :imageData="currentImageData"
      v-show="showWidget"
      :similarData="similarWidget"
      @update-is-collected="handleUpdateIsCollected"
      @similarWidget="showImage"
      @hide="hideWidget"
      @editWidget="showEditWidget"
    />
  </div>

  <!-- 编辑组件 -->
  <div class="edit_widget_mask" v-show="isEditWidget">
    <EditWidgetPopup
    :imageDataCode="svgCode"
    :imageData="currentImageData"
    @hideEdit="hideWidget"/>
  </div>

  <!-- 截图大图详情 -->
  <div class="widget_large_mask" v-show="showAppImage" @click="hideDetailsImage">
    <IdeaPopup
      :identifier="clickedIdentifier"
      :title="selectedTitle"
      :coverImage="selectedCoverImage"
      :imageUrl="selectedDetailImageUrl ? selectedDetailImageUrl : ''"
      :tags="selectedTags"
      :similarWidgets="similarAppWidget"/>
  </div>

  <!-- 顶部导航栏 -->
  <TopNav :key="updateCandy" />

  <!-- 图片大图 -->
  <div class="widget_large_mask" v-show="showAiPng" @click="hideWidget">
    <AiPngPopup
      v-show="showAiPng"
      :imageData="currentAIPng"
      :similarData="similarAIPng"
      @similarWidget="showAIPng"
      @hide="hideWidget"/>
  </div>

  <!-- H5搜索结果也搜索框 -->
  <div class="searchresultmain">
    <div class="searchresultbox">
        <img class="search_icon" src="../assets/images/icon_sousuo.svg">
        <input placeholder="输入关键词或组件名称，回车搜索" v-model="searchTerm" @keydown.enter="handleEnter">
    </div>
  </div>

  <div class="loadingbox" v-show="loading">
    <img src="../assets/images/Loader.gif">
  </div>

  <!-- 搜索结果分类 -->
  <div class="result_nav">
    <span :class="{ active: type === 'png' }" @click="updateType('png')">AI图库</span>
    <span :class="{ active: type === 'widget' }" @click="updateType('widget')">组件</span>
    <span :class="{ active: type === 'tob' }" @click="updateType('tob')">B端</span>
    <span :class="{ active: type === 'graphic' }" @click="updateType('graphic')">平面</span>
    <span :class="{ active: type === 'screen' }" @click="updateType('screen')">截图</span>
    <span :class="{ active: type === 'app' }" @click="updateType('app')">应用</span>
  </div>

  <!-- 图库结果显示 -->
  <div class="searchresult" v-if="!loading && widgetData.length > 0 && type === 'png'" :style="{ width: containerWidth }">
    <div class="list_item_ai" v-for="png in widgetData" :key="png._id">
      <img v-lazy="png.cover"
          @load="handleImageLoad"
          @click="showAIPng(png._id)"/>
    </div>
  </div>

  <!-- 组件结果显示 -->
  <div class="searchresult" v-else-if="!loading && widgetData.length > 0 && type === 'widget'" :style="{ width: containerWidth }">
    <!-- 组件item -->
    <div class="list_item"
      v-for="widget in widgetData"
      :key="widget._id">
        <div class="widget_mask" @click="showImage(widget._id)">
          <button v-if="(!widget.author || widget.author._id !== userInfo._id) && widget.price > 0"
            @click="handleCopyCode(widget)"
            :disabled="isDisabled"
            :class="{ 'button-disabled': isDisabled }"
            @click.stop>复制组件 {{ widget.price }}🍬
          </button>
          <button v-else
            @click="handleCopyCode(widget)"
            :disabled="isDisabled"
            :class="{ 'button-disabled': isDisabled }"
            @click.stop>复制组件
          </button>
          <div class="widget_mask_img">
            <img @click="showEditWidget(widget._id)" src="../assets/images/icon_editwidget.svg" @click.stop/>
            <img src="../assets/images/icon_favorite_hovers.svg"
            v-if="widget.isCollected"
            @click="toggleCollect(widget._id)"
            @click.stop>
            <img src="../assets/images/icon_favorite.svg"
              v-else
              @click="toggleCollect(widget._id)"
              @click.stop>
          </div>
        </div>
        <!-- 封面 -->
        <img @click="showImage(widget._id)" v-lazy="widget.image" @load="handleImageLoad" />
        <!-- 标题、标签、作者 -->
        <div class="widget_title">
          <router-link :to="{ name: 'widgetdetails', params: { id: widget._id } }" target="_blank">
            <h1 title="组件详情">{{ widget.title }}</h1>
          </router-link>
        <div class="list_tags" @click.stop>
          <div class="list_tags_item"
            @click="searchByKeyword(tag)"
            v-for="tag in widget.tags"
            :key="tag">
            <span v-if="tag">#{{ tag }}</span>
          </div>
        </div>
        <!-- 作者和价格 -->
        <div class="widget_author_box">
          <div v-if="widget.author" class="widget_author">
            <router-link :to="{ name: 'userspace', params: { id: widget.author._id } }" target="_blank">
              <div class="widget_author_avatar">
                <img :src="widget.author.imgUrl">
              </div>
              <span>{{ widget.author.nickname }}</span>
            </router-link>
          </div>
          <div v-else class="widget_author_system">
            <div class="widget_author_avatar">
              <img src="../assets/images/img_about_logo.png">
            </div>
            <span>UI大师官方</span>
          </div>
          <div v-if="widget.price > 0" class="widget_price">
            {{ widget.price }}
            <img src="../assets/images/icon_candy.svg">
          </div>
          <div v-else class="widget_price_free">
            免费
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- B端组件结果显示 -->
  <div class="searchresult" v-else-if="!loading && widgetData.length > 0 && type === 'tob'" :style="{ width: containerWidth }">
  <!-- 组件item -->
  <div class="list_item"
    v-for="widget in widgetData"
    :key="widget._id">
      <div class="widget_mask" @click="showImage(widget._id)">
        <button
          v-if="(!widget.author || widget.author._id !== userInfo._id) && widget.price > 0"
          @click="handleCopyCode(widget)"
          :disabled="isDisabled"
          :class="{ 'button-disabled': isDisabled }"
          @click.stop>复制组件 {{ widget.price }}🍬
        </button>
        <button
          v-else
          @click="handleCopyCode(widget)"
          :disabled="isDisabled"
          :class="{ 'button-disabled': isDisabled }"
          @click.stop>复制组件
        </button>
        <div class="widget_mask_img">
          <img @click="showEditWidget(widget._id)" src="../assets/images/icon_editwidget.svg" @click.stop/>
          <img src="../assets/images/icon_favorite_hovers.svg"
          v-if="widget.isCollected"
          @click="toggleCollect(widget._id)"
          @click.stop>
          <img src="../assets/images/icon_favorite.svg"
            v-else
            @click="toggleCollect(widget._id)"
            @click.stop>
        </div>
      </div>
      <img @click="showImage(widget._id)" v-lazy="widget.image" @load="handleImageLoad" />
      <!-- 标题、标签、作者 -->
      <div class="widget_title">
        <router-link :to="{ name: 'widgetdetails', params: { id: widget._id } }" target="_blank">
          <h1 title="组件详情">{{ widget.title }}</h1>
        </router-link>
      <div class="list_tags" @click.stop>
        <div class="list_tags_item"
          @click="searchByKeyword(tag)"
          v-for="tag in widget.tags"
          :key="tag">
          <span v-if="tag">#{{ tag }}</span>
        </div>
      </div>
      <!-- 作者和价格 -->
      <div class="widget_author_box">
        <div v-if="widget.author" class="widget_author">
          <router-link :to="{ name: 'userspace', params: { id: widget.author._id } }" target="_blank">
            <div class="widget_author_avatar">
              <img :src="widget.author.imgUrl">
            </div>
            <span>{{ widget.author.nickname }}</span>
          </router-link>
        </div>
        <div v-else class="widget_author_system">
          <div class="widget_author_avatar">
            <img src="../assets/images/img_about_logo.png">
          </div>
          <span>UI大师官方</span>
        </div>
        <div v-if="widget.price > 0" class="widget_price">
          {{ widget.price }}
          <img src="../assets/images/icon_candy.svg">
        </div>
        <div v-else class="widget_price_free">
          免费
        </div>
      </div>
    </div>
  </div>
  </div>

  <!-- 平面组件结果显示 -->
  <div class="searchresult" v-else-if="!loading && widgetData.length > 0 && type === 'graphic'" :style="{ width: containerWidth }">
  <!-- 组件item -->
  <div class="list_item"
    v-for="widget in widgetData"
    :key="widget._id">
      <div class="widget_mask" @click="showImage(widget._id)">
        <button
          v-if="(!widget.author || widget.author._id !== userInfo._id) && widget.price > 0"
          @click="handleCopyCode(widget)"
          :disabled="isDisabled"
          :class="{ 'button-disabled': isDisabled }"
          @click.stop>复制组件 {{ widget.price }}🍬
        </button>
        <button
          v-else
          @click="handleCopyCode(widget)"
          :disabled="isDisabled"
          :class="{ 'button-disabled': isDisabled }"
          @click.stop>复制组件
        </button>
        <div class="widget_mask_img">
          <img @click="showEditWidget(widget._id)" src="../assets/images/icon_editwidget.svg" @click.stop/>
          <img src="../assets/images/icon_favorite_hovers.svg"
          v-if="widget.isCollected"
          @click="toggleCollect(widget._id)"
          @click.stop>
          <img src="../assets/images/icon_favorite.svg"
            v-else
            @click="toggleCollect(widget._id)"
            @click.stop>
        </div>
      </div>
      <img @click="showImage(widget._id)" v-lazy="widget.image" @load="handleImageLoad" />
      <!-- 标题、标签、作者 -->
      <div class="widget_title">
        <router-link :to="{ name: 'widgetdetails', params: { id: widget._id } }" target="_blank">
          <h1 title="组件详情">{{ widget.title }}</h1>
        </router-link>
      <div class="list_tags" @click.stop>
        <div class="list_tags_item"
          @click="searchByKeyword(tag)"
          v-for="tag in widget.tags"
          :key="tag">
          <span v-if="tag">#{{ tag }}</span>
        </div>
      </div>
      <!-- 作者和价格 -->
      <div class="widget_author_box">
        <div v-if="widget.author" class="widget_author">
          <router-link :to="{ name: 'userspace', params: { id: widget.author._id } }" target="_blank">
            <div class="widget_author_avatar">
              <img :src="widget.author.imgUrl">
            </div>
            <span>{{ widget.author.nickname }}</span>
          </router-link>
        </div>
        <div v-else class="widget_author_system">
          <div class="widget_author_avatar">
            <img src="../assets/images/img_about_logo.png">
          </div>
          <span>UI大师官方</span>
        </div>
        <div v-if="widget.price > 0" class="widget_price">
          {{ widget.price }}
          <img src="../assets/images/icon_candy.svg">
        </div>
        <div v-else class="widget_price_free">
          免费
        </div>
      </div>
    </div>
  </div>
  </div>

  <!-- 截图结果显示 -->
  <div class="searchresult_app" v-else-if="!loading && widgetData.length > 0 && type === 'screen'" :style="{ width: containerWidth }">
    <!-- 列表图 -->
    <div class="search_ui_list">
      <div
        class="search_ui_list_item"
        v-for="appui in widgetData"
        :key="appui._id">
        <img v-lazy="appui.url"
        @click="showDetailsImage('img',appui.title, appui.coverImage, appui.url, appui.tags)">
        <router-link :to="{ name: 'appdetails', params: { id: appui.appId } }" target="_blank">
          <div class="search_list_item_logo" v-show="appui.matchingTagsCount > 0">
              <img :src="appui.coverImage">
              <div class="search_list_item_logo_right">
                  <b>{{ appui.matchingTagsCount }}</b> 个组件
              </div>
          </div>
      </router-link>
      </div>
    </div>
  </div>

  <!-- 应用结果显示 -->
  <div class="searchresult_app" v-else-if="!loading && widgetData.length > 0 && type === 'app'" :style="{ width: containerWidth }">
    <div class="search_app_box" style="margin-bottom: .7rem;">
        <!-- 列表项 -->
        <div
          class="search_app_item"
          v-for="app in widgetData"
          :key="app._id">
            <router-link
              :to="{ name: 'appdetails', params: { id: app._id } }"
              target="_blank">
                <div class="search_app_item_top">
                  <img :src="app.coverImage">
                    <div class="search_app_item_top_right">
                        <h6>{{ app.title }}</h6>
                        <span>共 <b>{{ app.detailImages?.length || 0 }}</b> 张截图，<b>{{ app.appMatchingTagsCount }}</b> 个组件</span>
                    </div>
                </div>
              <!-- 3个缩略图 -->
              <div class="search_app_item_list">
                <img
                v-for="(image, index) in app.detailImages?.slice(0,3)"
                v-lazy="image.url"
                :key="index">
              </div>
          </router-link>
        </div>
    </div>
  </div>

  <!-- 空结果处理 -->
  <div class="searchresultnull" v-if="!loading && widgetData.length === 0">
    <div class="nullimg"><img src="../assets/images/null.svg"></div>
    <p>暂无结果，为你推荐以下组件</p>

    <div class="resul_null_list">
      <div
        class="result_list_item"
        v-for="widget in widgetTop.slice(0, 12)"
        :key="widget._id"
        @click="showImage(widget._id)"
        >
        <img :src="widget.image">
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, watch, watchEffect, provide, nextTick, onBeforeUnmount, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { get, post, del } from '../utils/request'
import TopNav from '../components/TopNav.vue'
import IdeaPopup from '../components/idea/IdeaPopup.vue'
import WidgetPopup from '../components/WidgetPopup.vue'
import EditWidgetPopup from '../components/widget/EditWidgetPopup.vue'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { ElMessage } from 'element-plus'
import { fetchTotalCount } from '../utils/utils'
import { useStore } from 'vuex'
import AiPngPopup from '@/components/widget/AiPngPopup.vue'

const route = useRoute()
const store = useStore()
const props = defineProps({
  type: String,
  q: String
})
const { userInfo, getUserInfo } = useUserEffect()
const widgetData = ref([]) // 列表数据
const similarWidget = ref([]) // 相似组件数据
const similarAppWidget = ref([]) // 截图弹窗相似组件
const router = useRouter() // 使用 useRouter 获取路由实例
const updateCandy = ref(0) // 加载顶部U糖初始值
const currentImageData = ref(null) // 弹窗大图
const showWidget = ref(false) // 弹窗
const isCollected = ref(false) // 收藏状态
provide('isCollected', isCollected) // 共享收藏状态给子组件
const widgetTop = ref([]) // 置顶组件
const loading = ref(true) // 加载状态
const array = ref([]) // 定义空数组存储元素高度
const columns = ref(5) // 默认列数
const containerWidth = ref('') // 浏览器宽度
const searchTerm = ref(props.q || '')
const type = ref(route.query.type || 'widget')
const selectedDetailImageUrl = ref(null) // 详情图
const selectedTitle = ref('') // 标题
const selectedCoverImage = ref('') // 封面
const selectedTags = ref([]) // 标签
const showAppImage = ref(false) // 大图弹窗
provide('showAppImage', showAppImage) // 共享状态给子组件
const clickedIdentifier = ref('') // 区分弹窗内容
const isEditWidget = ref(false) // 编辑组件弹窗
const svgCode = ref('') // 请求到的svg代码
const isDisabled = ref(false) // 允许点击状态
const showAiPng = ref(false) // 图片弹窗
const currentAIPng = ref(null) // AI图库弹窗大图
const similarAIPng = ref([]) // 相似AI图片

// 切换导航
const updateType = (newType) => {
  type.value = newType
  const term = searchTerm.value.trim()
  if (term !== '') {
    router.push({ name: 'search', query: { type: newType, q: term } })
  }
}

// 登录状态
const isLoggedIn = () => {
  return localStorage.getItem('isLogin') === 'true'
}

// 点击标签搜索
const searchByKeyword = (keyword) => {
  const newRoute = {
    name: 'search',
    query: { type: 'widget', q: keyword }
  }
  // 如果标签和当前路由q参数相同，不执行搜索
  if (newRoute.query.q === route.query.q) {
    ElMessage.warning('无需重复搜索！')
  } else {
    router.push(newRoute)
  }
}

// 搜索结果搜索逻辑
const fetchResultData = async (query, type) => {
  try {
    let response
    if (type === 'widget') {
      response = await get(`/api/widget/search/${query}`)
    } else if (type === 'png') {
      response = await get(`/api/aipng/search/${query}`)
    } else if (type === 'tob') {
      response = await get(`/api/widget/search/tob/${query}`)
    } else if (type === 'graphic') {
      response = await get(`/api/widget/search/graphic/${query}`)
    } else if (type === 'screen') {
      response = await get(`/api/appui/search/tags/${query}`)
    } else if (type === 'app') {
      response = await get(`/api/appui/search/title/${query}`)
    }
    if (response?.errno === 0) {
      // 获取用户已收藏的组件ID列表
      let userFavorites = []
      if (isLoggedIn()) {
        userFavorites = await getUserFavorites()
      }
      // 提取收藏的组件ID
      const collectedIds = Array.isArray(userFavorites) ? userFavorites.map(item => item.widgetId._id.toString()) : []
      widgetData.value = response.data.list || response.data || []
      // 查询列表数据后更新布局
      waitForImagesToLoad()
      nextTick(() => {
        getWaterfall()
      })
      // 在组件列表中标记已收藏的组件
      widgetData.value.forEach(item => {
        if (isLoggedIn()) {
          item.isCollected = collectedIds.some(collectedId => collectedId === item._id.toString())
        } else {
          item.isCollected = false // 未登录时，所有组件都是未收藏状态
        }
      })
    }
  } catch (error) {
    console.error('搜索请求失败:', error)
  }
}

// 搜索组件逻辑
// const fetchData = async (query) => {
//   try {
//     loading.value = true
//     const response = await get(`/api/widget/search/${query}`)
//     if (response?.errno === 0 && Array.isArray(response.data)) {
//       // 获取用户已收藏的组件ID列表
//       let userFavorites = []
//       if (isLoggedIn()) {
//         userFavorites = await getUserFavorites()
//       }
//       // 提取收藏的组件ID
//       const collectedIds = Array.isArray(userFavorites) ? userFavorites.map(item => item.widgetId._id.toString()) : []
//       widgetData.value = response.data || []
//       waitForImagesToLoad()
//       // 更新布局
//       nextTick(() => {
//         getWaterfall()
//       })
//       // 在组件列表中标记已收藏的组件
//       widgetData.value.forEach(item => {
//         if (isLoggedIn()) {
//           item.isCollected = collectedIds.some(collectedId => collectedId === item._id.toString())
//         } else {
//           item.isCollected = false // 未登录时，所有组件都是未收藏状态
//         }
//       })
//     }
//   } catch (error) {
//     console.error('搜索请求失败:', error)
//   } finally {
//     loading.value = false // 无论请求成功还是失败，都将 loading 设置为 false
//   }
// }

// 组件挂载时立即请求数据
onMounted(async () => {
  await getUserInfo() // 获取用户信息

  // 全部搜索
  fetchResultData(searchTerm.value, type.value)
  // 判断输入框并搜索组件
  // if (props.q) {
  //   fetchData(props.q)
  // }
  updateColumns() // 更新列布局
  getWidgetTop() // 获取顶部组件
  await waitForImagesToLoad() // 等待图片加载完成
  // 更新布局
  await nextTick(() => {
    getWaterfall()
    loading.value = false // 设置加载状态为 false
  })

  // 添加窗口大小变化的监听器
  window.addEventListener('resize', handleResize)

  // 监听 userInfo._id 的变化并执行 fetchTotalCount
  watchEffect(() => {
    const userId = userInfo._id
    if (userId) {
      fetchTotalCount(userInfo._id, store)
    }
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

// 监听 widget 数据变化
watch(widgetData, () => {
  nextTick(() => {
    waitForImagesToLoad().then(() => {
      getWaterfall()
      loading.value = false // 图片加载完成后，隐藏 loading 图
    })
  })
})

// 监听路由变化
watch(route, (newRoute) => {
  type.value = newRoute.query.type || 'widget'
  searchTerm.value = newRoute.query.q || ''
  fetchResultData(searchTerm.value, type.value)
}, { immediate: true })

// 推荐组件
const getWidgetTop = async () => {
  if (widgetTop.value.length > 0) {
    return
  }
  const result = await get('/api/widget/top')
  if (result?.errno === 0 && result?.data?.length) {
    widgetTop.value = result.data
  }
}

// 搜索组件逻辑
// const searchMobile = (keyword) => {
//   if (searchTerm.value.trim() !== '') {
//     router.push({
//       name: 'search',
//       query: { type: 'widget', q: keyword }
//     })
//   }
// }

// 查询已收藏
const getUserFavorites = async () => {
  try {
    if (!isLoggedIn()) {
      return [] // 如果未登录，则直接返回空数组或其他默认值
    }
    const userId = userInfo._id
    const result = await get(`/api/collect/${userId}`)
    if (result?.errno === 0 && result?.data) {
      return result.data.widgets // 返回已收藏组件的数组
    }
  } catch (error) {
    console.error('查询收藏失败:', error.message)
    throw error
  }
}

// 组件弹窗点击后回传组件ID和收藏状态
const handleUpdateIsCollected = (itemId, newValue) => {
  widgetData.value.forEach(item => {
    if (item._id === itemId) {
      item.isCollected = newValue
    } else {
      item.isCollected = false
    }
  })
}

// 收藏和取消组件
const toggleCollect = async (itemId) => {
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage({
      message: '请先登录！',
      type: 'warning'
    })
    return
  }
  const userId = userInfo._id
  isCollected.value = await checkIfCollected(userId, itemId)
  let result
  if (isCollected.value) {
    widgetData.value.forEach(item => {
      if (item._id === itemId) {
        item.isCollected = false
      }
    })
    result = await removeCollect(userId, itemId)
    fetchTotalCount(userInfo._id, store)
    ElMessage.success('已取消收藏！')
  } else {
    result = await post('/api/collect', { userId, widgetId: itemId })
    fetchTotalCount(userInfo._id, store)
    ElMessage.success('收藏成功！')
  }
  if (result?.errno === 0) {
    // 更新 widget.value 数组中对应项的 isCollected 属性
    widgetData.value.forEach(item => {
      if (item._id === itemId) {
        item.isCollected = !isCollected.value
      }
    })
  }
}

// 取消收藏组件方法
const removeCollect = async (userId, itemId) => {
  try {
    const response = await del(`/api/collect/${userId}/${itemId}`)
    if (response.data.errno === 0) {
      return response.data // 返回成功的响应数据
    }
  } catch (error) {
    console.error('取消收藏失败:', error)
    throw error
  }
}

// 检查当前数据是否已收藏
const checkIfCollected = async (userId, itemId) => {
  try {
    const response = await get(`/api/collect/check/${userId}/${itemId}`)
    if (response?.errno === 0 && response?.data) {
      return true
    }
  } catch (error) {
    console.error('检查是否收藏失败:', error.message)
  }
  return false
}

// 获取单个组件逻辑
const showImage = async (id) => {
  if (isLoggedIn()) {
    const userId = userInfo._id
    isCollected.value = await checkIfCollected(userId, id)
  }
  try {
    const result = await get(`/api/widget/${id}`)
    if (result?.errno === 0 && result?.data) {
      currentImageData.value = result.data // 更新当前图片数据
      enterWidgetPopup() // 显示图片弹窗
      getWidgetByTags(id) // 按标签查询相似组件
    } else {
      console.error('获取失败:', result?.errmsg || '未知错误')
    }
  } catch (error) {
    console.error('获取失败:', error.message)
  }
}

// 显示大图弹窗
const showDetailsImage = async (identifier, title, coverImage, url, tags) => {
  clickedIdentifier.value = identifier
  selectedDetailImageUrl.value = url // 更新当前图片
  selectedTitle.value = title
  selectedCoverImage.value = coverImage
  selectedTags.value = tags
  showAppImage.value = true // 显示弹窗
  disableScroll() // 禁止页面滚动
  await getWidgetByUITags(tags) // 按标签查询相似组件
}

// 【截图】按标签查询相似组件
const getWidgetByUITags = async (tags) => {
  const response = await post('/api/widget/search/uiTags', { tags })
  if (response?.errno === 0 && response?.data) {
    similarAppWidget.value = response.data
  }
}
// 关闭大图弹窗
const hideDetailsImage = async () => {
  showAppImage.value = false
  enableScroll()
  reloadCandy() // 重新加载顶部U糖数据
}

// 显示弹窗时禁止页面滚动
const disableScroll = () => {
  document.body.style.overflow = 'hidden'
}
const enableScroll = () => {
  document.body.style.overflow = ''
}

// 【组件】按标签查询相似组件
const getWidgetByTags = async (id) => {
  const tags = currentImageData.value.tags
  const response = await post('/api/widget/search/tags', { tags, id })
  if (response?.errno === 0 && response?.data) {
    similarWidget.value = response.data
  }
}

// 显示图片弹窗
const enterWidgetPopup = () => {
  showWidget.value = true
  if (showWidget.value) {
    document.body.style.overflow = 'hidden'
  } else {
    // 隐藏子组件后恢复滚动
    document.body.style.overflow = 'auto'
  }
}

// 隐藏弹窗
const hideWidget = () => {
  showWidget.value = false // 子组件点击按钮隐藏后的回调
  isEditWidget.value = false
  showAiPng.value = false
  document.body.style.overflow = 'auto' // 恢复滚动
  reloadCandy() // 重新加载顶部U糖数据
}

// 重新加载顶部U糖数据
const reloadCandy = () => {
  updateCandy.value = Math.floor(Math.random() * 100) // 随机数变化更新组件
}

// 记录U糖变化
const createCandyLogBase = async ({ userId, widgetId, type, copyType, candys }) => {
  const currentDate = new Date() // 获取中国北京时间（UTC+8）
  const offset = 8 * 60 // UTC+8 的分钟数
  const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
    .toISOString()
    .replace('Z', '+08:00')
  try {
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('widgetId', widgetId)
    formData.append('type', type)
    formData.append('copyType', copyType)
    formData.append('candys', candys)
    formData.append('addTime', addTime)
    const result = await post('/api/candyLog', formData)
    if (result?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}
// 记录用户U糖变化
const createCandyLog = async (widget) => {
  const userId = userInfo._id
  const widgetId = widget._id
  const type = '复制组件'
  const copyType = false
  const candys = widget.price
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}
// 生成免费复制记录
const createFreeCandyLog = async (imageData) => {
  const userId = userInfo._id
  const widgetId = imageData._id
  const type = '复制组件'
  const copyType = false
  const candys = 0
  await createCandyLogBase({ userId, widgetId, type, copyType, candys })
}
// 记录作者U糖变化
const createCandyLogInauthor = async (widget) => {
  const authorId = widget.author?._id
  const widgetId = widget._id
  const type = '组件被复制'
  const copyType = true
  const candys = widget.price
  await createCandyLogBase({ userId: authorId, widgetId, type, copyType, candys })
}

// 更新作者U糖
const upCandyInauthor = async (widget) => {
  try {
    const authorId = widget.author?._id
    const candyResult = await post(`/api/user/add-candys/${authorId}`, { candys: widget.price })
    if (candyResult?.errno === 0) {
      return
    }
  } catch (e) {
    console.error(e)
  }
}

// 复制组件逻辑
const copyCode = async (widget) => {
  const userId = userInfo._id
  const authorId = widget.author?._id // 使用可选链操作符检查 author 是否存在

  // 复制代码到剪贴板的通用函数
  const copyToClipboard = (code) => {
    const textarea = document.createElement('textarea')
    textarea.value = code
    textarea.style.position = 'fixed' // 避免在页面上滚动
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  //  监测是否为Safari浏览器
  function isSafari () {
    const ua = navigator.userAgent.toLowerCase()
    return ua.includes('safari') && !ua.includes('chrome')
  }
  // 检测是否是 Safari 浏览器
  if (isSafari()) {
    ElMessage.error('当前浏览器暂不支持复制组件！')
    return
  }

  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage.warning('请先登录！每天登录可获得10U糖')
    return
  }

  try {
    // 如果用户是作者，直接复制代码
    if (userId === authorId || widget.price === 0) {
      const result = await get(`/api/widget/code/${widget._id}`)
      if (result?.errno === 0) {
        copyToClipboard(result.data.code)
        ElMessage.success('组件已复制，请返回设计工具粘贴！')
        await createFreeCandyLog(widget) // 生成免费记录
      } else {
        ElMessage.error('复制失败!')
      }
    } else {
      // 如果没有作者信息或作者不是当前用户，判断U糖数量
      if (userInfo.candys < widget.price) {
        ElMessage.error('U糖不足！每天登录可获得10U糖')
        return
      }

      // 扣除用户U糖
      const candyResult = await post(`/api/user/candys/${userId}`, { candys: widget.price })
      if (candyResult?.errno === 0) {
        await createCandyLogInauthor(widget) // 生成作者记录
        await createCandyLog(widget) // 生成用户记录
        await upCandyInauthor(widget)
        reloadCandy() // 重新加载顶部U糖数据
      } else {
        ElMessage.error('扣除积分失败！')
        return
      }

      // 复制组件代码
      const result = await get(`/api/widget/code/${widget._id}`)
      if (result?.errno === 0) {
        ElMessage.success('组件已复制，请返回设计工具粘贴！')
        copyToClipboard(result.data.code)
      } else {
        ElMessage.error('复制失败!')
      }
    }
  } catch (error) {
    ElMessage.error('复制组件异常，请稍后重试!')
  }
}

// 限制频繁复制
const handleCopyCode = async (widget) => {
  if (isDisabled.value) return

  isDisabled.value = true
  await copyCode(widget) // 执行相关逻辑

  // *秒后重新允许点击
  setTimeout(() => {
    isDisabled.value = false
  }, 2000)
}

const handleResize = () => {
  updateColumns()
  nextTick(() => {
    getWaterfall()
  })
}

// 等待所有图片加载完成的函数
const waitForImagesToLoad = () => {
  const imgElements = document.querySelectorAll('.list_item img')
  const imgPromises = [] // 存储所有图片加载的 Promise

  // 遍历每一个图片元素
  imgElements.forEach(img => {
    const imgPromise = new Promise((resolve) => {
      // 如果图片已经加载完成，则立即解析 Promise
      if (img.complete && img.naturalHeight !== 0) {
        resolve()
      } else {
        // 如果图片没有加载完成，则监听图片的 onload 事件
        img.onload = () => resolve()
        img.onerror = () => resolve() // 处理加载错误的情况
      }
    })
    // 将该 Promise 添加到 imgPromises 数组中
    imgPromises.push(imgPromise)
  })

  return Promise.all(imgPromises)
}

// 根据浏览器宽度显示列数和调整容器宽度
const updateColumns = () => {
  const width = window.innerWidth
  if (width >= 500 && width <= 1600) {
    columns.value = 4
    containerWidth.value = '11.9rem'
  } else if (width === 1920) {
    columns.value = 6
    containerWidth.value = '18rem'
  } else if (width <= 430) {
    columns.value = 2
    containerWidth.value = '3.9rem'
  } else {
    columns.value = 5
    containerWidth.value = '14.8rem'
  }
}

// 瀑布流布局方法
const getWaterfall = () => {
  // 选择所有带有类名 'list_item' 的元素
  const items = document.querySelectorAll('.list_item, .list_item_ai')
  // 如果没有找到任何元素，则返回
  if (items.length === 0) return

  // 初始化或重置列高度数组，将所有值设置为0，数组的长度为列数
  array.value = new Array(columns.value).fill(0)
  // 遍历每一个元素
  items.forEach((item, index) => {
    if (index < columns.value) {
      // 如果元素的索引小于列数，表示该元素属于第一行
      item.style.top = '20px' // 设置顶部位置
      item.style.left = `${item.clientWidth * index}px` // 设置左侧位置，按列数排列
      array.value[index] = item.clientHeight // 记录当前列的高度
    } else {
      // 如果元素的索引大于等于列数，表示该元素属于后续行
      const minHeight = Math.min(...array.value) // 找到当前最小的列高度
      const minIndex = array.value.findIndex(height => height === minHeight) // 找到最小高度的列索引
      item.style.top = `${minHeight + 25}px` // 设置顶部位置，距离最小高度列的底部 25px
      item.style.left = `${items[minIndex].offsetLeft}px` // // 设置左侧位置，与最小高度列对齐
      array.value[minIndex] += item.clientHeight // 更新最小高度列的高度
    }
  })
}

// 图片加载完成后，重新计算布局
const handleImageLoad = () => {
  getWaterfall() // 重新计算瀑布流布局
}

// 请求svg代码
const getCode = async (id) => {
  try {
    const result = await get(`/api/widget/code/${id}`)
    if (result?.errno === 0) {
      svgCode.value = result.data.code // 先将获取的数据存储到 code
    }
  } catch (error) {
    ElMessage.error('获取失败')
  }
}

// 显示编辑组件弹窗
const showEditWidget = async (id) => {
  // 验证是否登录
  const isLoggedIn = localStorage.getItem('isLogin') === 'true'
  if (!isLoggedIn) {
    ElMessage.warning('请先登录！')
    return
  }

  try {
    const result = await get(`/api/widget/${id}`)
    if (result?.errno === 0 && result?.data) {
      currentImageData.value = result.data // 更新当前图片数据
      getCode(id)
      enterEditWidgetPopup() // 显示图片弹窗
    } else {
      console.error('获取失败:', result?.errmsg || '未知错误')
    }
  } catch (error) {
    console.error('获取失败:', error.message)
  }
}

// 显示编辑弹窗
const enterEditWidgetPopup = () => {
  isEditWidget.value = true
  showWidget.value = false // 隐藏组件弹窗
  if (isEditWidget.value) {
    document.body.style.overflow = 'hidden'
  } else {
    // 隐藏子组件后恢复滚动
    document.body.style.overflow = 'auto'
  }
}

// 按标签查询相似图片
const getAIPngByTags = async (id) => {
  const tags = currentAIPng.value.tags
  const response = await post('/api/aipng/search/tags', { tags, id })
  if (response?.errno === 0 && response?.data) {
    similarAIPng.value = response.data
  }
}

// 显示AI图库弹窗
const showAIPng = async (id) => {
  try {
    const result = await get(`/api/aipng/${id}`)
    if (result?.errno === 0 && result?.data) {
      currentAIPng.value = result.data // 更新当前图片数据
      showAiPng.value = true
      document.body.style.overflow = 'hidden'
      // 更新浏览器地址栏中的 URL
      // const newUrl = `/aigc/${id}`
      // window.history.pushState({}, '', newUrl)
      getAIPngByTags(id) // 按标签查询相似组件
    } else {
      document.body.style.overflow = 'auto'
      console.error('获取失败:', result?.errmsg || '未知错误')
    }
  } catch (error) {
    console.error('获取失败:', error.message)
  }
}
</script>

<style lang="scss">
@import '../style/widgeview.scss'
</style>
