<!-- 登录弹窗 -->
<template>
  <div class="login_box">
    <div class="login_input">
      <input placeholder="请输入手机号或邮箱" v-model="identifier">
      <input placeholder="请输入密码" type="password" v-model="password">
      <button @click="handleLogin">进入UI大师</button>
      <div class="login_option">
        <span v-if="!isResetRoute"><router-link to="/reset">忘记密码？</router-link></span>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserEffect } from '../store/modules/useUserEffect'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'Login',
  setup () {
    const { handleLogin, identifier, password } = useUserEffect()
    const route = useRoute()
    // 如果已在重置密码页，隐藏忘记密码按钮
    const isResetRoute = computed(() => route.path === '/reset')
    watch(route, (newRoute) => {
    })

    return {
      handleLogin,
      identifier,
      password,
      isResetRoute
    }
  }
}
</script>

<style lang="scss">

</style>
