<!-- 注册弹窗 -->
<template>
  <div class="login_box">
    <div class="login_input">
      <div class="question_mark" v-if="question">
        <div class="question">
          <div class="question_title">
            <strong>请回答问题 {{ question }} =</strong>
            <div class="refresh_btn" @click="showVerify">换一题</div>
          </div>
          <input v-model="userAnswer" placeholder="请输入正确答案完成验证" />
          <button class="cancel_btn" @click="closeVerify">取消</button>
          <button class="submit_btn" @click="submitAnswer">提交</button>
      </div>
    </div>
      <input placeholder="请输入手机号" v-model="phoneNumber">
      <div class="smscode">
        <input placeholder="请输入短信验证码" v-model="smsCode">
        <button id="captcha-button" :disabled="isDisabled" @click="showVerify">{{ buttonText }}</button>
      </div>
      <input type="password" placeholder="请输入登录密码" v-model="password">
      <input type="password" placeholder="请再次输入登录密码" v-model="ensurement">
      <button @click="handleRegister">加入UI大师</button>
      <div class="login_option">
        <input type="checkbox" id="checkbox" v-model="agreeProtocol">
        <label for="checkbox">我已阅读并同意UI大师<router-link to="/treaty" target="_blank">《用户服务协议&隐私政策》</router-link></label>
      </div>
    </div>
  </div>
  </template>

<script>
import { toRefs, reactive, ref, computed, onMounted } from 'vue'
import { post, get } from '@/utils/request'
import { ElMessage } from 'element-plus'

export default {
  name: 'SignUp',
  setup () {
    // 定义响应式状态
    const data = reactive({
      phoneNumber: '',
      smsCode: '',
      password: '',
      ensurement: '',
      agreeProtocol: false
    })

    const question = ref(null)
    const questionId = ref(null)
    const userAnswer = ref('')
    const countdown = ref(0) // 倒计时初始0
    const isDisabled = computed(() => countdown.value > 0) // 倒计时大于0时禁用
    const buttonText = computed(() => {
      return countdown.value > 0 ? `${countdown.value}秒后重新获取` : '获取验证码'
    })

    // 安全认证-----------------------------
    const showVerify = async () => {
      if (!data.phoneNumber || !/^1\d{10}$/.test(data.phoneNumber)) {
        ElMessage.warning('请输入正确的手机号码！')
        return
      }
      try {
        const response = await get('/api/user/question')
        if (response) {
          question.value = response.question
          questionId.value = response.questionId
        } else {
          throw new Error('请求出错，请稍后重试！')
        }
      } catch (error) {
        console.error('请求出错，请稍后重试！:', error)
      }
    }
    // 取消验证
    const closeVerify = async () => {
      question.value = null
    }

    const submitAnswer = async () => {
      try {
        const response = await post('/api/user/answer', {
          questionId: questionId.value,
          userAnswer: userAnswer.value
        })
        if (response.correct) {
          sendSmsCode()
          question.value = null
        } else {
          ElMessage.warning('答案有误，请重试！')
          showVerify()
          userAnswer.value = ''
        }
      } catch (error) {
        console.error('提交出错:', error)
      }
    }

    // 获取验证码-----------------------------
    const sendSmsCode = async () => {
      try {
        const checkResult = await post('/api/user/phonenumber', { phoneNumber: data.phoneNumber })
        if (checkResult?.errno === 0) {
          const result = await post('/api/user/sendsmscode', { phoneNumber: data.phoneNumber })
          if (result && result.success) {
            ElMessage.success('验证码已发送，请注意查收！')

            // 禁用获取按钮60秒
            countdown.value = 60 // 设置倒计时为60秒
            localStorage.setItem('sendSmsCode', Date.now().toString()) // 记录当前时间到本地
            const interval = setInterval(() => {
              // 计算已经过去的时间
              const elapsed = Math.floor((Date.now() - Number(localStorage.getItem('sendSmsCode'))) / 1000)
              countdown.value = 60 - elapsed
              if (countdown.value <= 0) {
                clearInterval(interval)
                localStorage.removeItem('sendSmsCode') // 移除localStorage中的记录
              }
            }, 1000)
            initializeCountdown() // 初始化倒计时的方法
          } else {
            ElMessage.error('发送验证码失败')
            console.error('发送验证码失败:', result.message)
          }
        }
      } catch (error) {
        if (error.response) {
          question.value = null // 隐藏验证弹窗
          const errorMsg = error.response.data?.message || '该手机号已注册！'
          ElMessage.error(errorMsg)
        } else {
          ElMessage.error('获取验证码失败，请检查手机号或稍后重试')
          console.error('发送请求失败:', error)
        }
      }
    }

    // 初始化倒计时的方法--------------------------
    const initializeCountdown = () => {
      const start = localStorage.getItem('sendSmsCode') // 从localStorage中获取开始时间
      if (start) {
        // 计算已经过去的时间
        const elapsed = Math.floor((Date.now() - Number(start)) / 1000)
        countdown.value = 60 - elapsed
        if (countdown.value > 0) {
          const interval = setInterval(() => {
            countdown.value--
            if (countdown.value <= 0) {
              clearInterval(interval)
              localStorage.removeItem('sendSmsCode')
            }
          }, 1000) // 每秒更新一次倒计时
        }
      }
    }

    onMounted(() => {
      initializeCountdown() // 初始化倒计时的方法
    })

    // 注册账号----------------------------
    const handleRegister = async () => {
      try {
        // 验证手机号格式
        if (!data.phoneNumber || !/^\d{11}$/.test(data.phoneNumber)) {
          ElMessage.warning('手机号格式不正确！')
          return
        }

        if (data.smsCode === '') {
          ElMessage.warning('请输入短信验证码！')
          return
        }

        // 验证密码格式
        const passwordRegex = /^[a-zA-Z0-9]{6,}$/ // 只包含数字或字母，且长度至少为6位
        if (!passwordRegex.test(data.password)) {
          ElMessage.warning('只允许数字或字母，且长度6位以上！')
          return
        }

        // 检查密码是否一致
        if (data.password !== data.ensurement) {
          ElMessage.warning('两次密码输入不一致！')
          return
        }

        // 检查是否勾选了协议
        if (!data.agreeProtocol) {
          ElMessage.warning('请先勾选并阅读UI大师使用协议')
          return
        }

        // 发送注册请求
        const result = await post('/api/user/register', {
          phoneNumber: data.phoneNumber,
          code: data.smsCode,
          password: data.password
        })
        if (result?.errno === 0) {
          ElMessage.success('欢迎加入UI大师，请先登录！')
          window.location.reload()
        } else {
          // 根据返回的错误代码和消息显示错误信息
          switch (result.errno) {
            case 10003:
              ElMessage.error('手机号格式不正确')
              break
            case 10004:
              ElMessage.error('密码只能包含数字或字母，且长度至少为6位')
              break
            case 10005:
              ElMessage.error('手机号已被注册')
              break
            case 10002:
              ElMessage.error('验证码有误')
              break
            default:
              ElMessage.error(`注册失败: ${result?.message || '未知错误'}`)
              break
          }
        }
      } catch (e) {
        alert('数据请求失败，请稍后重试！')
      }
    }

    return {
      ...toRefs(data),
      handleRegister,
      isDisabled,
      buttonText,
      sendSmsCode,
      question,
      questionId,
      userAnswer,
      showVerify,
      submitAnswer,
      closeVerify
    }
  }
}
</script>

<style lang="scss">
@import '../style/user/sigup.scss'
</style>
